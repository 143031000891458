html,
body {
  max-width: 100vw !important;
  overflow-x: hidden !important;
  background-color: #ececec;
  font-size: 0.9rem;
}

.btn-neutral {
  border: none;
  background-color: transparent;
}

.btn-neutral:focus {
  outline: none;
  background-color: transparent;
}

.btn-filter {
  outline: none;
  background-color: transparent;
  padding: 4px 18px 4px 18px;
  border-width: 1px;
  border-color: black;
  border-radius: 13px;
  border-style: solid;
  transition: background-color 500ms;
}

.btn-filter.active {
  outline: none;
  background-color: black;
  color: white;
}

.btn-filter:focus {
  outline: none;
}

.input-group-append {
  z-index: 1;
}

.ReactModal__Overlay {
  z-index: 2;
}

.ReactModal__Content {
  max-height: 95% !important;
}

.link-hover:hover {
  text-decoration: underline;
}

.separator {
  border-top: 1px solid #0000002f;
  width: 100%;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

#content-wrapper {
  background-color: #e4e6ed !important;
}

.slider-wrapper {
  height: auto !important;
  transition: height 500ms !important;
}

select:required:invalid {
  color: #c4c4c4 !important;
}

@media (max-width: 768px) {
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .hide-on-mobile {
    display: none !important;
  }
  .ReactModal__Content {
    width: 93% !important;
  }
  .btn-checkout-boleto-text {
    margin-top: 11px;
    margin-bottom: 11px;
  }
}
