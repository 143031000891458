.carousel .slide {
  height: 100%;
  background-color: transparent;
}

.order-details-status-label {
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
}

.order-details-status-label.created {
  background-color: grey;
}

.order-details-status-label.waiting {
  background-color: grey;
}

.order-details-status-label.paid {
  background-color: #1cc839;
}

.order-details-status-label.analysis {
  background-color: #4e73df;
}

.order-details-status-label.cancelled {
  background-color: #c73442;
}

@media (max-width: 768px) {
  .order-data {
    font-size: 14px;
  }
  .order-details-status-label.created {
    font-size: 12px;
  }
}
