.checkout-root-container {
  width: 100%;
}

.checkout-topbar {
  height: 130px;
  background-color: #101455 !important;
}

.checkout-loading-container {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
}

.checkout-wrapper {
  background-color: #ececec !important;
}
