.order-status-pill {
  width: 10px;
  height: 25px;
  display: inline-block;
  border-radius: 20px;
}

.btn-load-more {
  color: #4e73df;
  font-size: 16px;
}

.btn-load-more:hover {
  text-decoration: underline;
}

.order-status-pill.created {
  background-color: grey;
}

.order-status-pill.waiting {
  background-color: grey;
}

.order-status-pill.paid {
  background-color: #1cc839;
}

.order-status-pill.analysis {
  background-color: #4e73df;
}

.order-status-pill.cancelled {
  background-color: #c73442;
}

@media (max-width: 768px) {
  .order-container {
    font-size: 13px;
    text-transform: capitalize;
  }
  .order-value {
    color: #4e73df;
  }
  .order-status-label {
    color: white;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
  }
  .order-status-label.created {
    background-color: grey;
  }
  .order-status-label.waiting {
    background-color: grey;
  }
  .order-status-label.paid {
    background-color: #1cc839;
  }
  .order-status-label.analysis {
    background-color: #4e73df;
  }
  .order-status-label.cancelled {
    background-color: #c73442;
  }
}
